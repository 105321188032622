import {FocusLock} from '../utils/focus-lock.js';
import {ScrollLock} from '../utils/scroll-lock.js';


const focusLock = new FocusLock();
const scrollLock = new ScrollLock();

// Меню
export const initMenu = function () {
  const menu = document.querySelector('.nav');
  const menuBtn = document.querySelector('.nav__toggle');


  const onMenuEscKeydown = (evt) => {
    if (evt.keyCode === 27) {
      evt.preventDefault();
      closeMenu();
    }
  };

  const overlayClose = (evt) => {
    const target = evt.target;
    if (!target.closest('.nav--opened')) {
      closeMenu();
    }
  };

  function openMenu() {
    menu.classList.add('nav--opened');
    focusLock.lock('.header');
    scrollLock.disableScrolling();
    document.addEventListener('keydown', onMenuEscKeydown);
    document.addEventListener('click', overlayClose);
  }

  function closeMenu() {
    menu.classList.remove('nav--opened');
    focusLock.unlock();
    scrollLock.enableScrolling();
    document.removeEventListener('keydown', onMenuEscKeydown);
    document.removeEventListener('click', overlayClose);
  }

  function initmenu() {
    if (menu.classList.contains('nav--opened')) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  menuBtn.addEventListener('click', initmenu);
};
